import { createSlice } from "@reduxjs/toolkit";
import { getUser, profilePhoto } from "./actions";
import { Storage } from "@utils";

const initialState = {
  userCountry: null,
  coordinates: null,
  defaultCountryCoordinates: null,
  recentSearches: [],
  recentViewedNews: [],
  userdata: [],
  image: null,
  language: { code: "GB", language: "en" },
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setUserCountry: (state, action) => {
      state.userCountry = action.payload;
    },
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setDefaultCountryCoordinates: (state, action) => {
      state.defaultCountryCoordinates = action.payload;
    },
    upsertRecentSearches: (state, action) => {
      state.recentSearches = action.payload;
    },
    upsertRecentViewedNews: (state, action) => {
      state.recentViewedNews = action.payload;
    },
    deleteUserData: (state, action) => {
      state.userdata = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.userdata = action?.payload?.data;
      let userData = {
        jwt: JSON.parse(localStorage.getItem("authentication"))?.jwt,
        user: action?.payload?.data,
      };
      Storage.save(Storage.AUTHENTICATION, JSON.stringify(userData));
      Storage.save(Storage.USER_DATA, JSON.stringify(action?.payload?.data));
    });
    builder.addCase(profilePhoto.fulfilled, (state, action) => {
      state.image = action?.payload?.data;
      //localStorage.setItem("userImage",action.payload.data)
    });
  },
});

export const {
  setLanguage,
  setUserCountry,
  setCoordinates,
  setDefaultCountryCoordinates,
  upsertRecentSearches,
  upsertRecentViewedNews,
  deleteUserData,
} = slice.actions;
export default slice.reducer;
